import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Post from '../components/Post'
import Sidebar from '../components/Sidebar'
import favicon from './favicon.ico'
import profilePic from './photo.jpg'

class IndexRoute extends React.Component {
  render() {
    const items = []
    const { title, subtitle, url } = this.props.data.site.siteMetadata
    const posts = this.props.data.allMarkdownRemark.edges
    posts.forEach(post => {
      items.push(<Post data={post} key={post.node.fields.slug} />)
    })

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={`${title} is ${subtitle}`} />
            <link rel="icon" href={favicon} />
            <link rel="canonical" href='https://www.vincentgache.com' />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content="https://www.vincentgache.com" />
            <meta property="og:description" content={`${title} is ${subtitle}`} />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:site_name" content={title} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@smashou" />
            <meta name="twitter:title" content={`${title}`} />
            <meta name="twitter:description" content={`${title} is ${subtitle}`} />
          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">{items}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          rss
          vk
          linkedin
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`
